import React from "react";
import BarCode from "assets/images/icons/barcode.svg";
import * as S from "./styles";
import { useNavigate, useParams } from "react-router-dom";
import PayCar from "assets/images/icons/gray-car-circle.svg";
import usePayment from "hooks/usePayment";
import { useWhitelabel } from "hooks/useWhitelabel";

export const ButtonsStartInstallment: React.FC = () => {
  const navigate = useNavigate();
  const { id_usuario } = useParams();
  const { clearPaymentBilletData } = usePayment();
  const { services } = useWhitelabel();
  const {
    pay_billet_installments,
    label_pay_billet_installments,
    description_pay_billet_installments,
    // pay_taxes,
    // label_pay_taxes,
    // description_pay_taxes,
    pay_vehicle_debts,
    label_pay_vehicle_debts,
    description_pay_vehicle_debts,
    transfer_bank,
    label_transfer_bank,
    description_transfer_bank,
  } = services;

  const navigateToPartner = () => {
    clearPaymentBilletData();
    navigate(id_usuario ? `/parceiro/${id_usuario}` : "/parceiro");
  };

  return (
    <S.Container>
      <S.WrapperContainer>
        {pay_billet_installments ? (
          <S.ButtonLeft
            onClick={() =>
              navigate(id_usuario ? `/boleto/${id_usuario}` : "/boleto")
            }
          >
            <S.RowIconTitleButton>
              <S.IconButton src={BarCode} />
              <S.TitleButton>{label_pay_billet_installments}</S.TitleButton>
            </S.RowIconTitleButton>
            <S.SubTitleButton>
              {description_pay_billet_installments}
            </S.SubTitleButton>
          </S.ButtonLeft>
        ) : null}

        {transfer_bank ? (
          <S.ButtonLeft
            onClick={() =>
              navigate(
                id_usuario ? `/transferencia/${id_usuario}` : "/transferencia"
              )
            }
          >
            <S.RowIconTitleButton>
              <S.IconButton src={BarCode} />
              <S.TitleButton>{label_transfer_bank}</S.TitleButton>
            </S.RowIconTitleButton>
            <S.SubTitleButton>{description_transfer_bank}</S.SubTitleButton>
          </S.ButtonLeft>
        ) : null}

        {pay_vehicle_debts ? (
          <S.ButtonLeft onClick={() => navigateToPartner()}>
            <S.RowIconTitleButton>
              <S.IconButton src={PayCar} />
              <S.TitleButton>{label_pay_vehicle_debts}</S.TitleButton>
            </S.RowIconTitleButton>
            <S.SubTitleButton>{description_pay_vehicle_debts}</S.SubTitleButton>
          </S.ButtonLeft>
        ) : null}
      </S.WrapperContainer>

      <S.WrapperContainer>
        {/* {pay_taxes ? (
          <a
            href="https://portal.parcelamostudo.com.br/payment_online/parceiros?is_tax=true"
            target="_blank"
            rel="noreferrer"
          >
            <S.ButtonRight>
              <S.RowIconTitleButton>
                <S.IconButton src={Tax} />
                <S.TitleButton>{label_pay_taxes}</S.TitleButton>
              </S.RowIconTitleButton>
              <S.SubTitleButton>{description_pay_taxes}</S.SubTitleButton>
            </S.ButtonRight>
          </a>
        ) : null} */}

        {/* <S.ButtonRight>
          <S.RowIconTitleButton>
            <S.IconButton src={RechargePhone} />
            <S.TitleButton>Recarregar celular</S.TitleButton>
          </S.RowIconTitleButton>
          <S.SubTitleButton>
            Recarregue seu telefone e pague parcelado.
          </S.SubTitleButton>
        </S.ButtonRight> */}
      </S.WrapperContainer>
    </S.Container>
  );
};

import React from "react";
import { Provider } from "react-redux";
import "antd/dist/reset.css";
import Routes from "routes";
import { store } from "store";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { PaymentProvider } from "hooks/usePayment";
import { ThemeProvider } from "styled-components";
import { theme } from "theme/theme";
import { WhitelabelProvider } from "hooks/useWhitelabel";
import { ApiResponseError, getApiErrorMessages } from "./services/errors";

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const responseError = err.response.data as ApiResponseError;

        const messageError = getApiErrorMessages(responseError);

        toast.error(messageError);
      },
    },
    queries: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onError: (err: any) => {
        const responseError = err.response?.data as ApiResponseError;

        const messageError = getApiErrorMessages(responseError);

        toast.error(messageError);
      },
    },
  },
});

const App = () => {
  return (
    <Provider store={store}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss={false}
        draggable={false}
        pauseOnHover
        theme="light"
      />
      <QueryClientProvider client={queryClient}>
          <PaymentProvider>
            <ThemeProvider theme={theme}>
              <WhitelabelProvider>
                <Routes />
              </WhitelabelProvider>
            </ThemeProvider>
          </PaymentProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default App;

import React, { useMemo } from "react";
import {
  CardGrid,
  Column,
  PaymentTrackingContainer,
  StatusImg,
  SubTitle,
  Title,
} from "./styles";
import {
  CheckoutIdStatus,
  EnumChargeType,
} from "views/Billet/providers/dto/create-checkout.dto";
import { Row } from "antd";
import CheckedIcon from "../../assets/images/icons/green-check.svg";
import ErrorIcon from "../../assets/images/icons/error-circle.svg";
import PointerIcon from "../../assets/images/icons/pointer-green.svg";

const ChargeStageCases = [
  "INITIATED",
  "CREATED",
  "3DS_VALIDATION_REQUESTED",
  "3DS_APPROVED",
  "RISK_ANALYSIS_STARTED",
  "RISK_ANALYSIS_APPROVED",
  "CHARGE_WAITING",
];

const ChargeStageFailCases = [
  "3DS_ERROR",
  "RISK_ANALYSIS_REPROVED",
  "CHARGE_NOT_SUCCEEDED",
];

const PaymentStageCases = [
  "CHARGE_SUCCEEDED",
  "PAYMENT_STARTED",
  "PAYMENT_DONE",
  "PAYMENT_ERROR",
  "PAYMENT_WAITING",
];

const CompletedStagecases = ["COMPLETED", "CHARGEBACK", "REVERSED", "REMOVED"];

type Props = {
  stageStatus: CheckoutIdStatus;
  type: EnumChargeType;
};

type IPhases = {
  second: {
    color: string;
    icon: SVGElement | null;
    activeStep: boolean;
  };
  third: {
    color: string;
    icon: SVGElement | null;
    activeStep: boolean;
  };
  forth: {
    color: string;
    icon: SVGElement | null;
    activeStep: boolean;
  };
};

type IStage = "charge" | "payment";

export const PaymentTracking: React.FC<Props> = ({ stageStatus, type }) => {
  const stages: IPhases = useMemo(() => {
    if (ChargeStageCases.includes(stageStatus)) {
      return {
        second: {
          activeStep: true,
          icon: PointerIcon,
          color: "#22C55E",
        },
        third: {
          activeStep: false,
          icon: null,
          color: "#2121211F",
        },
        forth: {
          activeStep: false,
          icon: null,
          color: "#2121211F",
        },
      };
    }
    if (ChargeStageFailCases.includes(stageStatus)) {
      return {
        second: {
          activeStep: false,
          icon: ErrorIcon,
          color: "#DC2626",
        },
        third: {
          activeStep: false,
          icon: null,
          color: "#2121211F",
        },
        forth: {
          activeStep: false,
          icon: null,
          color: "#2121211F",
        },
      };
    }
    if (PaymentStageCases.includes(stageStatus)) {
      return {
        second: {
          activeStep: false,
          icon: CheckedIcon,
          color: "#22C55E",
        },
        third: {
          activeStep: true,
          icon: PointerIcon,
          color: "#22C55E",
        },
        forth: {
          activeStep: false,
          icon: null,
          color: "#2121211F",
        },
      };
    }
    if (CompletedStagecases.includes(stageStatus)) {
      return {
        second: {
          activeStep: false,
          icon: CheckedIcon,
          color: "#22C55E",
        },
        third: {
          activeStep: false,
          icon: CheckedIcon,
          color: "#22C55E",
        },
        forth: {
          activeStep: false,
          icon: CheckedIcon,
          color: "#22C55E",
        },
      };
    }
    return {
      second: {
        activeStep: true,
        icon: null,
        color: "#22C55E",
      },
      third: {
        activeStep: false,
        icon: null,
        color: "#2121211F",
      },
      forth: {
        activeStep: false,
        icon: null,
        color: "#2121211F",
      },
    };
  }, [stageStatus, type]);

  const Icon = (icon: any) => {
    if (icon) {
      return <StatusImg src={icon} />;
    }
    return null;
  };

  const StepMessage = (active: boolean, stage: IStage) => {
    if (active && type === EnumChargeType.PIX && stage === "charge") {
      return (
        <SubTitle>
          Estamos aguardando o seu <br />
          pagamento via PIX
        </SubTitle>
      );
    }
    if (active && type === EnumChargeType.CREDIT_CARD && stage === "charge") {
      return (
        <SubTitle>
          Efetuando a cobrança <br />
          no seu cartão
        </SubTitle>
      );
    }
    if (active && stage === "payment") {
      return (
        <SubTitle>
          Cobrança bem sucedida! <br />
          Seguiremos com a liquidação <br /> dos seus débitos.
        </SubTitle>
      );
    }

    return null;
  };

  return (
    <PaymentTrackingContainer>
      <CardGrid
        color="#22C55E"
        style={{ borderRightColor: stages.second.color }}
      >
        <Row align="middle">
          <StatusImg src={CheckedIcon} />
          <Title>Inicio</Title>
        </Row>
      </CardGrid>
      <CardGrid color={stages.second.color}>
        <Column>
          <Row align="middle">
            {Icon(stages.second.icon)}
            <Title>Cobrança</Title>
          </Row>
          {StepMessage(stages.second.activeStep, "charge")}
        </Column>
      </CardGrid>
      <CardGrid color={stages.third.color}>
        <Column>
          <Row align="middle">
            {Icon(stages.third.icon)}
            <Title>Pagamento</Title>
          </Row>
          {StepMessage(stages.third.activeStep, "payment")}
        </Column>
      </CardGrid>
      <CardGrid color={stages.forth.color}>
        <Column>
          <Row align="middle">
            {Icon(stages.forth.icon)}
            <Title>Finalizado</Title>
          </Row>
        </Column>
      </CardGrid>
    </PaymentTrackingContainer>
  );
};

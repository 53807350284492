/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ParcelamosTudo3DS } from "@parcelamostudo-tech/lib-3ds-client";

import {
  cpfSensibleMask,
  creditCardSensibleMask,
  formatCurrency,
  sum,
  checkFlag,
  roundTwoDecimals,
} from "utils";
import { Button } from "components";
import usePayment from "hooks/usePayment";
import { IInstallmentItem, IStagesBilletSummary } from "models";
import { EnumPaymentForm } from "services";
import { EnumStages } from "views/Partner/types";
import Pencil from "assets/images/icons/pencil.svg";
import Pix from "assets/images/icons/gray-cicle-pix.svg";
import Barcode from "assets/images/icons/gray-circle-barcode.svg";
import CreditCard from "assets/images/icons/gray-circle-card.svg";
import UserPlus from "assets/images/icons/gray-circle-user-plus.svg";
import PublicBffService from "../../services/PublicBff";

import * as S from "./styles";
import { toast } from "react-toastify";
import { useWhitelabel } from "hooks";
import { apm } from "services/Apm";
import {
  CreateCheckoutOrderRequest,
  CreateCheckout,
  ThreeDs,
  EnumCheckoutStatus,
} from "services/PublicBff/type";
import {
  EnumOriginPayment,
  EnumTypeDebit,
  MappedDebitsToGetInstallments,
  getInstallments,
} from "services/CreditCard";
import { useMutation } from "react-query";
import {
  ApiResponseError,
  EnumErrorCode,
  getApiErrorMessages,
} from "services/errors";
import ButtonRecaptcha from "components/ButtonRecaptcha";

enum EnumStatus3DS {
  STARTED = "Inicializando pagamento autenticado com 3DS",
  PAYMENT_INITIATED = "Pagamento iniciado autenticado com 3DS",
  INITIALIZING_CHALLENGE = "Inicializando desafio autenticado com 3DS",
  CHALLENGE_COMPLETED = "Desafio finalizado pelo usuário autenticado com 3DS",
  WATING_CONFIRMATION_CHALLENGE = "Aguardando confirmação da resposta do desafio autenticado com 3DS",
  CHARGE_SUCCESS = "Cobrança realizada com sucesso",
}

const TOAST_3DS_ID = "toast.payment.threeds";
const TOAST_3DS_AUTO_CLOSE = 3000;

export const Summary: React.FC<IStagesBilletSummary> = ({
  route,
  paymentForm,
  enable,
  stage,
  goBack,
  setButtonDisabled,
  buttonDisabled,
  whitelabelUserId,
}) => {
  const { userIdWhitelabel, client: clientWhitelabel } = useWhitelabel();
  const navigate = useNavigate();
  const {
    dataPaymentBillet,
    paymentCard,
    client,
    installments,
    updateInstallments,
    dataTransferBank,
    setPaymentCard,
  } = usePayment();
  const [isLoading, setIsLoading] = useState(false);

  const [tokenRecaptcha, setTokenRecaptcha] = useState<string | null>(null);

  const { mutateAsync: mutateGetIntallments } = useMutation({
    mutationFn: async () => {
      const billets: MappedDebitsToGetInstallments =
        dataPaymentBillet.billets.map((billet) => ({
          type: EnumTypeDebit.BILLET,
          id_bill: billet.id_bill,
        }));
      const carDebits: MappedDebitsToGetInstallments =
        dataPaymentBillet.debits.map((debit) => ({
          type: EnumTypeDebit.CAR_DEBIT,
          id_debit: debit.id_debit,
        }));
      const pixDebits: MappedDebitsToGetInstallments =
        dataPaymentBillet.pix.map(({ id_pix }) => ({
          type: EnumTypeDebit.PIX,
          id_pix,
        }));
      const debits: MappedDebitsToGetInstallments = [
        ...billets,
        ...carDebits,
        ...pixDebits,
      ];

      const request = {
        idEstablishment: clientWhitelabel?.id_usuario,
        data: { origin: EnumOriginPayment.ONLINE, debits },
      };

      const installments = await getInstallments(request);

      return installments;
    },
    onSuccess(data) {
      if (paymentForm === EnumPaymentForm.CREDIT) {
        const findInstallments = data.credit_card.find(
          (option) =>
            option.installment_number ===
            paymentCard.installment.installment_number
        );

        if (!findInstallments) {
          const message = "Tempo para pagamento expirou, tente novamente!";
          toast.update(TOAST_3DS_ID, {
            render: message,
            type: "error",
            autoClose: TOAST_3DS_AUTO_CLOSE,
            isLoading: false,
          });
          return;
        }

        setPaymentCard({
          ...paymentCard,
          id_charge_option: findInstallments.id_charge_option,
          installment: findInstallments,
        });
      }

      if (paymentForm === EnumPaymentForm.DEBIT) {
        const installment = data.debit_card[0];

        setPaymentCard({
          ...paymentCard,
          id_charge_option: installment.id_charge_option,
          installment,
        });
      }

      if (paymentForm === EnumPaymentForm.PIX) {
        const installment = data.pix;

        setPaymentCard({
          ...paymentCard,
          id_charge_option: installment.id_charge_option,
          installment,
        });
      }
    },
  });

  const { mutateAsync: mutateCreateCheckoutOrder3ds } = useMutation({
    mutationFn: PublicBffService.createCheckoutOrder3ds,
    async onError(error) {
      const responseError = (error as any).response.data as ApiResponseError;

      if (responseError.error === EnumErrorCode.CHARGE_CHARGE_OPTIONS_EXPIRED) {
        let newChargeOption = {} as IInstallmentItem;

        const newInstallments = await mutateGetIntallments();

        if (paymentForm === EnumPaymentForm.PIX) {
          newChargeOption = newInstallments.pix;
        }

        if (paymentForm === EnumPaymentForm.DEBIT) {
          newChargeOption = newInstallments.debit_card[0];
        }

        if (paymentForm === EnumPaymentForm.CREDIT) {
          const credidInstallmentSelected = newInstallments.credit_card.find(
            (option) =>
              option.installment_number ===
              paymentCard.installment.installment_number
          );

          if (!credidInstallmentSelected) {
            const message = "Tempo para pagamento expirou, tente novamente!";
            toast.update(TOAST_3DS_ID, {
              render: message,
              autoClose: TOAST_3DS_AUTO_CLOSE,
              type: "error",
              isLoading: false,
            });
            return;
          }

          newChargeOption = credidInstallmentSelected;
        }

        updateInstallments(newInstallments);
        setPaymentCard({
          ...paymentCard,
          id_charge_option: newChargeOption.id_charge_option,
          installment: newChargeOption,
        });

        await handleInitPayment();

        return;
      }

      const messageError = getApiErrorMessages(responseError);

      toast.update(TOAST_3DS_ID, {
        render: messageError,
        autoClose: TOAST_3DS_AUTO_CLOSE,
        type: "error",
        isLoading: false,
      });
    },
    retry: false,
  });
  const { mutateAsync: mutateCreateCheckout } = useMutation({
    mutationFn: PublicBffService.createCheckout,
    onSuccess(data) {
      if (data.challenge_url && data.credential_request) return;

      if (data.id_status !== EnumCheckoutStatus.THREEDS_VALIDATION_REQUESTED) {
        toast.update(TOAST_3DS_ID, {
          render: EnumStatus3DS.CHARGE_SUCCESS,
          autoClose: TOAST_3DS_AUTO_CLOSE,
          type: "success",
          isLoading: false,
        });

        setTimeout(() => {
          navigate(`/checkout/${data.id_checkout}`);
        }, TOAST_3DS_AUTO_CLOSE + 500);
      }
    },
    async onError(error) {
      const responseError = (error as any).response.data as ApiResponseError;

      if (
        responseError.error === EnumErrorCode.CHECKOUT_CHARGE_OPTIONS_EXPIRED
      ) {
        let newChargeOption = {} as IInstallmentItem;

        const newInstallments = await mutateGetIntallments();

        if (paymentForm === EnumPaymentForm.PIX) {
          newChargeOption = newInstallments.pix;
        }

        if (paymentForm === EnumPaymentForm.DEBIT) {
          newChargeOption = newInstallments.debit_card[0];
        }

        if (paymentForm === EnumPaymentForm.CREDIT) {
          const credidInstallmentSelected = newInstallments.credit_card.find(
            (option) =>
              option.installment_number ===
              paymentCard.installment.installment_number
          );

          if (!credidInstallmentSelected) {
            const message = "Tempo para pagamento expirou, tente novamente!";
            toast.update(TOAST_3DS_ID, {
              render: message,
              autoClose: TOAST_3DS_AUTO_CLOSE,
              type: "error",
              isLoading: false,
            });
            return;
          }

          newChargeOption = credidInstallmentSelected;
        }

        updateInstallments(newInstallments);
        setPaymentCard({
          ...paymentCard,
          id_charge_option: newChargeOption.id_charge_option,
          installment: newChargeOption,
        });

        await handleInitPayment();

        return;
      }

      const messageError = getApiErrorMessages(responseError);

      toast.update(TOAST_3DS_ID, {
        render: messageError,
        autoClose: TOAST_3DS_AUTO_CLOSE,
        type: "error",
        isLoading: false,
      });
    },
    retry: false,
  });

  const { mutateAsync: mutateConfirm3ds } = useMutation({
    mutationFn: PublicBffService.confirmCheckout3ds,
    onSuccess(data) {
      if (data.id_status !== EnumCheckoutStatus.THREEDS_VALIDATION_REQUESTED) {
        toast.update(TOAST_3DS_ID, {
          render: EnumStatus3DS.CHARGE_SUCCESS,
          autoClose: TOAST_3DS_AUTO_CLOSE,
          type: "success",
          isLoading: false,
        });

        setTimeout(() => {
          navigate(`/checkout/${data.id_checkout}`);
        }, TOAST_3DS_AUTO_CLOSE + 500);
      }
    },
    async onError(error) {
      const responseError = (error as any).response.data as ApiResponseError;

      const messageError = getApiErrorMessages(responseError);

      toast.update(TOAST_3DS_ID, {
        render: messageError,
        autoClose: TOAST_3DS_AUTO_CLOSE,
        type: "error",
        isLoading: false,
      });
    },
    retry: false,
  });

  const amountBillets = dataPaymentBillet?.billets?.map(
    (billet) => billet.amount.total
  );
  const amountDebits = dataPaymentBillet?.debits?.map((debit) => debit.amount);
  const amountPix = dataPaymentBillet?.pix?.map((pix) => pix.amount.total);

  const amountTransfers = dataTransferBank.amount ?? 0;

  const sumBillets = Number(
    sum([
      ...amountBillets,
      ...amountDebits,
      ...amountPix,
      amountTransfers,
    ]).toFixed(2)
  );

  useEffect(() => {
    const route_to_updated = whitelabelUserId
      ? `/${route}/${whitelabelUserId}`
      : `/${route}`;
    if (sumBillets === 0) {
      navigate(route_to_updated, { state: { stage: EnumStages.SEARCH } });
    }
    if (stage === EnumStages.SUMMARY && !paymentCard?.number) {
      navigate(route_to_updated, {
        state: { stage: EnumStages.PAYMENT_FORM },
      });
    }
  }, [sumBillets]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      const confirmationMessage = "Tem certeza que deseja sair?";
      event.preventDefault();
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  async function handleInitPayment() {
    if (!tokenRecaptcha) return;

    const token = tokenRecaptcha;
    setButtonDisabled(true);
    setIsLoading(true);

    try {
      const paymentFormIsPix = paymentForm === EnumPaymentForm.PIX;

      if (!paymentFormIsPix) {
        toast.loading(EnumStatus3DS.STARTED, { toastId: TOAST_3DS_ID });
      }

      apm?.setUserContext({ id: client.id_client });

      if (paymentFormIsPix) {
        const payloadCheckoutPix = {
          id_client: client.id_client,
          charges: [
            {
              id_charge_option: installments.pix.id_charge_option,
            },
          ],
        };

        await mutateCreateCheckout({
          payload: payloadCheckoutPix,
          id_establishment: userIdWhitelabel,
          token,
        });

        return;
      }

      const [month, year] = paymentCard.due_date.split("/");

      const card = {
        exp_month: month,
        exp_year: year,
        holder_name: paymentCard.name,
        number: paymentCard.number,
        security_code: paymentCard.cvv,
      };

      const payloadCheckoutCard = {
        id_client: client.id_client,
        charges: [
          {
            id_charge_option: paymentCard.id_charge_option,
            card,
          },
        ],
      };

      const { id_three_ds, validation_method_token, validation_method_url } =
        await mutateCreateCheckoutOrder3ds({
          payload: payloadCheckoutCard,
          id_establishment: userIdWhitelabel,
          token,
        });

      if (!id_three_ds) {
        return;
      }

      toast.update(TOAST_3DS_ID, { render: EnumStatus3DS.PAYMENT_INITIATED });

      const parcelamos3DS = new ParcelamosTudo3DS();

      const three_ds: ThreeDs = {
        id: id_three_ds,
        provider: "ENTREPAY",
      };

      if (validation_method_token && validation_method_url) {
        const { validation_time_to_complete } =
          await parcelamos3DS.executeCustomerValidation({
            id_three_ds,
            validation_method_token,
            validation_method_url,
          });

        three_ds.validation_time_to_complete = validation_time_to_complete;
      }

      const payloadWithThree_ds: CreateCheckoutOrderRequest = {
        ...payloadCheckoutCard,
        charges: [
          {
            ...payloadCheckoutCard.charges[0],
            card: {
              ...card,
              three_ds,
            },
          },
        ],
      };

      const browserInfoHeaders = parcelamos3DS.getBrowserData();

      const { challenge_url, credential_request, id_checkout, id_status } =
        await mutateCreateCheckout({
          payload: payloadWithThree_ds,
          id_establishment: userIdWhitelabel,
          browserInfoHeaders,
          token,
        });

      if (challenge_url && credential_request) {
        toast.update(TOAST_3DS_ID, {
          render: EnumStatus3DS.INITIALIZING_CHALLENGE,
        });

        await parcelamos3DS.executeChallenge({
          challenge_url,
          credential_request,
          id_three_ds,
        });

        toast.update(TOAST_3DS_ID, {
          render: EnumStatus3DS.CHALLENGE_COMPLETED,
        });
      }

      if (id_status === EnumCheckoutStatus.THREEDS_VALIDATION_REQUESTED) {
        await mutateConfirm3ds({
          id_checkout,
          id_establishment: userIdWhitelabel,
          payload: payloadWithThree_ds,
          browserInfoHeaders,
          token,
        });
      }
    } catch (error: any) {
      if (error.name !== "AxiosError") {
        const message = "Houve um erro ao iniciar pagamento, tente novamente!";
        toast.update(TOAST_3DS_ID, {
          render: message,
          autoClose: TOAST_3DS_AUTO_CLOSE,
          type: "error",
          isLoading: false,
        });

        console.error(error);
      }
      apm?.captureError(error);
    } finally {
      setTimeout(() => {
        setButtonDisabled(false);
        setIsLoading(false);
      }, TOAST_3DS_AUTO_CLOSE);
    }
  }

  function handleVerifyRecaptcha(token: string | null) {
    setTokenRecaptcha(token);
  }

  return (
    <S.ResumeWrapper enable={enable}>
      <S.WrapperBox>
        <S.TitleRow>
          <S.Title>Resumo</S.Title>
        </S.TitleRow>

        <S.TitleForm>
          Veja como estão as coisas por aqui, antes de seguir para o pagamento.
        </S.TitleForm>

        <S.ClientIdentificationContainer>
          <S.UserImg src={UserPlus} />
          <S.ClientIdentificationContent>
            <S.Label>Identificação</S.Label>
            <S.ClientText>{client.name}</S.ClientText>
            <S.ClientText>{cpfSensibleMask(client.document)}</S.ClientText>
          </S.ClientIdentificationContent>
        </S.ClientIdentificationContainer>

        <S.ValueContainer>
          <S.BarcodeImg src={Barcode} />
          <S.ValueContent>
            <S.Label>Valor dos débitos</S.Label>
            <S.ValueText>{formatCurrency(sumBillets)}</S.ValueText>
          </S.ValueContent>
        </S.ValueContainer>

        <S.PaymentInfoContainer>
          <S.PaymentImg
            src={paymentForm === EnumPaymentForm.CREDIT ? CreditCard : Pix}
          />
          <S.PaymentContent>
            <S.Label>Pagamento</S.Label>
            {paymentForm === EnumPaymentForm.CREDIT ? (
              <>
                <S.CardInfoRow>
                  <S.CardFlag
                    src={checkFlag({ cardNumber: paymentCard?.number })}
                  />
                  <S.CardNumberText>
                    {creditCardSensibleMask(paymentCard?.number)}
                  </S.CardNumberText>
                </S.CardInfoRow>
              </>
            ) : (
              <S.PixText>PIX</S.PixText>
            )}
            <S.TotalValueText>
              {paymentForm === EnumPaymentForm.CREDIT
                ? paymentCard?.installment?.description
                : formatCurrency(installments?.pix?.total_amount)}
            </S.TotalValueText>
          </S.PaymentContent>
          <S.PencilButton disabled={buttonDisabled}>
            <S.PencilImg
              src={Pencil}
              onClick={() => goBack(EnumStages.PAYMENT_FORM)}
            />
          </S.PencilButton>
        </S.PaymentInfoContainer>

        <S.ServiceFeeContainer>
          <S.Label>Tarifa de Serviço</S.Label>
          <S.TotalValueText>
            {paymentForm === EnumPaymentForm.CREDIT
              ? formatCurrency(
                  roundTwoDecimals(
                    paymentCard?.installment?.total_amount - sumBillets
                  )
                )
              : formatCurrency(
                  roundTwoDecimals(installments?.pix?.total_amount - sumBillets)
                )}
          </S.TotalValueText>
        </S.ServiceFeeContainer>

        <S.DashedLine />

        <S.TotalContainer>
          <S.Label>Total a pagar</S.Label>
          <S.TotalValueText>
            {paymentForm === EnumPaymentForm.CREDIT
              ? formatCurrency(paymentCard?.installment?.total_amount)
              : formatCurrency(installments?.pix?.total_amount)}
          </S.TotalValueText>
        </S.TotalContainer>

        <S.ContainerButtons>
          <S.ContainerButtonItem>
            <ButtonRecaptcha onVerify={handleVerifyRecaptcha} />
          </S.ContainerButtonItem>
          <S.ContainerButtonItem>
            <Button
              title="Finalizar Pagamento"
              action={handleInitPayment}
              type_button="primary"
              loading={isLoading}
              disabled={!tokenRecaptcha || buttonDisabled}
            />
          </S.ContainerButtonItem>
        </S.ContainerButtons>
      </S.WrapperBox>
    </S.ResumeWrapper>
  );
};

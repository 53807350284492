import styled from "styled-components";
import { deviceMaxWidth } from "theme/theme";

export const SearchWrapper = styled.div<{ enable?: boolean }>`
	padding: 20px;
	box-sizing: border-box;

	display: ${({ enable }) => (enable ? "flex" : "none")};
`;

export const WrapperBox = styled.div`
	width: 100%;
	height: 100%;

	box-sizing: border-box;
`;

export const TitleRow = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 24px;
	
	@media ${deviceMaxWidth.tablet} {
		justify-content: center;
	}
`;

export const OrderImg = styled.img``;

export const Title = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 28px;
	line-height: 34px;
	color: #212121;
	margin: 0;
`;

export const TitleForm = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 300;
	font-size: 20px;
	line-height: 24px;
	color: #312f30;
	margin: 0;
	margin-bottom: 21px;
`;

export const WrapperBoxForm = styled.div`
	width: 100%;
	padding: 24px 24px;
	box-sizing: border-box;
`;

export const WrapperInput = styled.div`
	width: 100%;
	margin-bottom: 10px;
`;

export const WrapperButton = styled.div`
	width: 100%;
	display: flex;
	align-self: center;
	justify-content: flex-end;
`;

export const Button = styled.div`
	width: 114px;
	align-self: flex-end;
`;

export const ContainerButtons = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 30px;
`;

export const ContainerButtonItem = styled.div`
	width: auto;
	min-width: 200px;
`;

export const ResumeWrapper = styled(SearchWrapper)`
	width: clamp(350px, 100%, 800px);
`;

export const WrapperValue = styled.div`
	border: 1px dashed rgba(33, 33, 33, 0.12);
	border-radius: 4px;
	padding: 10px 10px;
	margin-bottom: 24px;
`;

export const LabelValue = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #212121;
	margin: 0;
	padding-bottom: 5px;
`;

export const Value = styled.div`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #212121;
	margin: 0;
`;

export const Dash = styled.div`
	border: 1px dashed #000000;
	margin-bottom: 24px;
`;

export const IdentifyYourself = styled.img``;

export const WrapperIdentifyTexts = styled.div`
	padding-left: 10px;
	padding-right: 10px;
`;

export const TitleIdentify = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	color: #212121;
	margin: 0;
	margin-bottom: 9px;
	text-align: left;
`;

export const SubTitleIdentify = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	color: #212121;
	margin: 0;
	text-align: left;
`;

export const IdentifyYourselfButton = styled.button<{ enable?: boolean }>`
	width: 100%;
	margin: 0;
	padding: 0;
	background-color: transparent;
	border: 0;
	cursor: pointer;
	display: ${({ enable }) => (enable ? "block" : "none")};
`;

export const PaymentWrapper = styled(SearchWrapper)`
	width: 610px;
`;

export const WrapperTypePayment = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 30px;
`;

export const WrapperRadio = styled.div`
	margin-right: 30px;
`;

export const WrapperCard = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 8px;
`;

export const IconCard = styled.img``;

export const BackgroundTextCard = styled.div`
	background: rgba(132, 159, 171, 0.1);
	border-radius: 4px;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 5px;
	box-sizing: border-box;
`;

export const TextCard = styled.p`
	font-family: "Inter";
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 17px;
	letter-spacing: -0.2px;
	color: rgba(33, 33, 33, 0.52);
	margin: 0;
`;

export const Dot = styled(TextCard)`
	color: rgba(33, 33, 33, 0.1);
`;

export const Border = styled.div`
	width: 100%;
	height: 1px;
	background-color: #f3f5f7;
	margin: 10px 0;
`;

export const ClientIdentificationContainer = styled.div`
	display: flex;
	padding: 10px 20px;
	align-items: center;
	gap: 11px;
	border-radius: 8px;
	border: 1px solid rgba(33, 33, 33, 0.12);
	margin-bottom: 30px;
`;

export const UserImg = styled.img``;

export const ClientIdentificationContent = styled.div`
	width: auto;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const Label = styled.p`
	color: #212121;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin: 5px 0px;
`;

export const ClientText = styled.p`
	color: #6b7280;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	margin: 5px 0px;
`;

export const ValueContainer = styled.div`
	display: flex;
	padding: 10px 20px;
	align-items: center;
	gap: 11px;
	border-radius: 8px;
	border: 1px solid rgba(33, 33, 33, 0.12);
	margin-bottom: 30px;
`;

export const BarcodeImg = styled.img``;

export const ValueContent = styled.div`
	width: auto;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const ValueText = styled.p`
	color: #212121;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0px;
`;

export const PaymentInfoContainer = styled.div`
	display: flex;
	padding: 10px 20px;
	align-items: center;
	gap: 11px;
	border-radius: 8px;
	border: 1px solid rgba(33, 33, 33, 0.12);
	margin-bottom: 30px;
`;

export const PaymentImg = styled.img``;

export const PencilImg = styled.img``;

export const PencilButton = styled.button`
	padding: 0;
	border: none;
	background: none;
	cursor: pointer;
`;

export const PaymentContent = styled.div`
	flex: 1;

	width: auto;
	height: auto;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
`;

export const CardInfoRow = styled.div`
	display: flex;
	width: auto;
	height: auto;
	align-items: center;
`;

export const CardFlag = styled.img`
	width: 34px;
	height: 24px;
	margin-right: 15px;
`;

export const CardNumberText = styled.p`
	color: #6b7280;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
`;

export const TotalValueText = styled.p`
	color: #212121;
	font-family: "Inter";
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	margin: 0;
	padding: 3px;
`;

export const PixText = styled.p`
	color: #6b7280;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin: 0;
`;

export const ServiceFeeContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;

	align-self: stretch;
	border-radius: 4px;
	border: 1px dashed var(--2-dark-outline, rgba(33, 33, 33, 0.12));
`;

export const DashedLine = styled.div`
	border-bottom: 1px dotted #000000;
	width: auto;
	margin: 30px 0px;
`;

export const TotalContainer = styled.div`
	display: flex;
	flex-direction: column;
	padding: 10px 20px;

	align-self: stretch;
	border-radius: 4px;
	border: 1px dashed var(--2-dark-outline, rgba(33, 33, 33, 0.12));
`;

import { api } from "services";

type GetCheckoutIdByIdentifierResponse = {
  id_checkout: string;
};

export async function getIdentifier(
  identifier: string,
  idEstablishment: string
) {
  const response = await api.get<GetCheckoutIdByIdentifierResponse>(
    `/checkout/identifier/${identifier}`,
    { headers: { "id-establishment": idEstablishment } }
  );

  return response.data;
}

import React from "react";
import ArrowDown from "assets/images/icons/arrow-down-gradient.svg";
import * as S from "./styles";
import { Button, BoxInstallment } from "components";

export const LandingSection: React.FC = () => {
  return (
    <S.Container id="home">
      <S.WrapperContainer>
        <S.WrapperInfos>
          <S.Title>Parcele tudo o que você precisar.</S.Title>
          <S.SubTitle>
            Não importa se é débitos do seu veículo, alguma compra ou os boletos
            da vida... Aqui você parcela tudo!
          </S.SubTitle>

          <S.WrapperButtons>
            {/* <S.Button href="#bank">
              <Button
                title="Abrir uma conta no Parcelamos_Bank"
                type_button="outlined"
              />
            </S.Button> */}

            <S.Button href="#help">
              <Button title="Ajuda" type_button="outlined" />
            </S.Button>
          </S.WrapperButtons>

          <S.WrapperArrowDown>
            <S.ArrowDown src={ArrowDown} />
          </S.WrapperArrowDown>
        </S.WrapperInfos>

        <S.WrapperInstallment>
          <BoxInstallment />
        </S.WrapperInstallment>
      </S.WrapperContainer>
    </S.Container>
  );
};
